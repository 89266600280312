const MILLISECOND = 1
export const SECOND = 1000 * MILLISECOND

export const FILE_LOADER_DELAY = 300 * MILLISECOND

export const MAX_INT_FOR_PAGINATION_SIZE = 100000

export enum EBuildEnv {
  Local = 'local',
  Staging = 'staging'
}

export const isLocalOrStagingBuild =
  process.env.ENVIRONMENT_BUILD === EBuildEnv.Local || process.env.ENVIRONMENT_BUILD === EBuildEnv.Staging

export const ROUTE_PLANNING_MAP_ID = 'ec8e1e66badeb6d5'
