import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const RoutingProjectsView = lazy(
  () => import(/* webpackChunkName: "routing-projects" */ /* webpackPrefetch: true */ './views/Index')
)
const RoutingProjectView = lazy(
  () => import(/* webpackChunkName: "routing-project" */ /* webpackPrefetch: true */ './views/RoutingProject')
)

export const RoutingProjectsRoutes = {
  path: '/routing-projects',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <RoutingProjectsView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':routingProjectId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <RoutingProjectView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/routing-projects" replace />
    }
  ]
}
